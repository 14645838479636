var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SignUpTemplate',{staticClass:"UserView",attrs:{"right-image":_vm.userSignUpImage,"step":_vm.getStepNumber}},[_c('div',{class:[
      'UserView__content--aligned h-100',
      {
        'UserView__content--step_1 p-0 w-100': _vm.isOnFirstStep || _vm.isOnInvitationFirstStep,
        'UserView__content--step_2 py-0': _vm.isOnSecondStep,
        'UserView__content--step_3': _vm.isOnThirdStep || _vm.isOnInvitationThirdStep,
      },
    ]},[_c('div',{staticClass:"navigator mt-md-0 pb-3 px-3 px-lg-0 w-100",class:_vm.isOnFirstStep ? 'mt-0': 'mt-5'},[_c('SignUpProgressBar',{attrs:{"step-number":_vm.getStepNumber,"go-to-step":{ path: `./step${_vm.getStepNumber - 1}` },"total-steps":_vm.isEmployee ? 2 : 3}})],1),_c('RouterView',{staticClass:"content",attrs:{"auth":_vm.auth}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }