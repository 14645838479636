<template>
  <div class="Step__form mt-2 mt-md-5 px-lg-0 px-3 pb-0 p-lg-0 position-relative">
    <div
      class="my-3"
      data-test-id="company_signup_form-step_3"
    >
      <h1
        data-test-id="company_signup_form-step_3"
        class="mb-5"
        v-html="$t('refactor.company_signup.step_3.title')"
      />

      <!-- Legal Documents -->
      <LegalDocumentsComponent
        v-if="documents"
        :accepted="acceptedLegalDocuments"
        :documents="documents"
        @accept="({ value }) => trackAndAcceptLegalDocuments(value)"
        @reject="({ value }) => rejectLegalDocument(value)"
      />

      <!-- Receive News -->
      <SubscriptionGroupsComponent
        :marketing-news="data.marketingTerms"
        :subscriptions="subscriptionGroups"
        @update:marketing-news="setMarketingTerms"
        @update:subscriptions="setSubscriptionGroups"
      >
        <template #marketing-news>
          {{ $t('refactor.company_signup.fields.marketingTerms', { operator: appName }) }}
        </template>
      </SubscriptionGroupsComponent>

      <ui-button
        v-bind="fetchButtonSpecs()"
        class="d-block mt-5"
        :disabled="isDisabled"
        :loading="loading"
        :size="SIZES.large"
        :type="BUTTON_TYPES.submit"
        data-test-id="submit-button"
        @click="signupCompany"
      >
        {{ $t('buttons.send') }}
      </ui-button>
      <FeedbackModalComponent
        v-model="modalSetup.isOpen"
        v-bind="modalSetup.props"
      />
    </div>
  </div>
</template>

<script>
import pick from 'lodash/pick';
import isPlainObject from 'lodash/isPlainObject';
import { useTheme } from '@/composable/Theme/useTheme';

import { mapActions, mapMutations, mapState } from 'vuex';
import {
  COUNTRIES_ISO_CODES, getValue, LOG_TYPE, logger, navigationErrorHandler,
} from '@emobg/web-utils';
import { DATETIME_FORMATS } from '@/constants/datetimeFormats';
import config from '@/config';
import { useSegment } from '@/composable/Segment/segment';
import { STORE_STEPS } from '@/constants/signUpSteps';
import {
  isLoading as areSubscriptionsLoading,
  fetchSubscriptions,
  isSaving,
  registerModule,
  setSubscriptionGroups,
  subscriptionGroups,
} from '@/stores/NotificationCenter/NotificationCenterMapper';
import { genericErrorArgs, genericSuccessArgs } from '@/constants/defaultModalArgs';
import { SEGMENT_EVENTS } from '@/vue/constants';
import LegalDocumentsComponent from '@/domains/Document/components/LegalDocuments/LegalDocumentsComponents';
import { useAcceptLegalDocuments } from '@/composable/User/legalDocuments';
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';
import { GTM_CLICK_ACTIONS_AREAS, GTM_EVENTS, GTM_SIGN_UP_EVENT_VALUES } from '@/constants/gtm';
import { useTrackingGTM } from '@/composable/GTM/gtm';

import COMPANY_SIGN_UP_ROUTES from '../../router/routes-names';
import SubscriptionGroupsComponent from '../../components/SubscriptionGroups/SubscriptionGroupsComponent';
import { COMPANY_MODULE, GENERIC_MODULE } from '../../constants/modules';

export default {
  name: 'Step3',
  components: {
    LegalDocumentsComponent,
    FeedbackModalComponent,
    SubscriptionGroupsComponent,
  },
  setup() {
    const { trackInteractionEvent } = useTrackingGTM();
    const {
      acceptedLegalDocuments,
      acceptLegalDocument,
      rejectLegalDocument,
      areAccepted,
    } = useAcceptLegalDocuments();

    const { trackSegment, trackSegmentGroup } = useSegment();
    const { fetchButtonSpecs } = useTheme();

    return {
      acceptedLegalDocuments,
      acceptLegalDocument,
      rejectLegalDocument,
      areAccepted,
      trackSegment,
      trackSegmentGroup,
      fetchButtonSpecs,
      trackInteractionEvent,
    };
  },
  data() {
    return {
      loading: false,
      modalSetup: {
        props: {
          ...genericSuccessArgs(this.$t),
          isOpen: false,
          title: this.$t('refactor.company_signup.titles.company_created'),
          primaryCallToActionText: this.$t('refactor.company_signup.actions.next'),
        },
      },
    };
  },
  computed: {
    areSubscriptionsLoading,
    isSaving,
    subscriptionGroups,
    // TODO [CF-557]: Refactor Company Signup store
    ...mapState(COMPANY_MODULE, {
      done: state => state.done,
      data: state => state.data,
      documents: state => state.info.legalDocuments,
      linkAccount: state => state.linkAccount,
    }),
    // TODO [CF-548]: Refactor Generic Signup store
    ...mapState(GENERIC_MODULE, {
      locale: state => state.locale,
    }),
    isDisabled() {
      const areDocumentsAccepted = this.areAccepted(this.documents || []);

      return this.areSubscriptionsLoading || !areDocumentsAccepted;
    },
  },
  beforeCreate() {
    registerModule(this);
  },
  created() {
    const query = getValue(this, '$route.query', {});

    this.appName = config.data.appName;
    this.COUNTRIES_ISO_CODES = COUNTRIES_ISO_CODES;
    this.undoStep(STORE_STEPS.three);

    if (!this.done.step2) {
      this.$router.push({ name: COMPANY_SIGN_UP_ROUTES.step2, query }).catch(navigationErrorHandler);
      return;
    }

    // When is not link account, we have to remove IDP_UUID from local storage
    if (!this.linkAccount.isLinkAccount) {
      localStorage.removeItem('expiresAt');
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('impersonate_accessToken');
      localStorage.removeItem('is_impersonate');
      localStorage.removeItem('impersonate_idpUUID');
    }

    this.setSubscriptionGroups([]);
    this.fetchSubscriptions({ trackingId: null });

    this.trackSegment({
      name: SEGMENT_EVENTS.B2B_STEP_3,
      cache: true,
    });
  },
  methods: {
    fetchSubscriptions,
    setSubscriptionGroups,
    // TODO [CF-557]: Refactor Company Signup store
    ...mapMutations(COMPANY_MODULE, [
      'adminFiscalNumber',
      'italyTermsAndConditions',
      'doneStep',
      'undoStep',
      'updateStep',
      'resetCompany',
    ]),
    // TODO [CF-557]: Refactor Company Signup store
    ...mapMutations(COMPANY_MODULE, {
      setMarketingTerms: 'marketingTerms',
    }),
    // TODO [CF-557]: Refactor Company Signup store
    ...mapActions(COMPANY_MODULE, [
      'createCompany',
      'linkUserCompany',
    ]),
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    async submitLinkAccount() {
      const { data } = await this.linkUserCompany({ locale: this.locale });
      this.modalSetup = {
        ...genericSuccessArgs(this.$t),
        title: this.$t('refactor.company_signup.titles.company_created'),
        primaryCallToActionText: this.$t('refactor.company_signup.actions.next'),
        primaryCallToAction: () => this.onClickModalPrimaryButton(getValue(data, 'admin')),
      };
    },
    async createNewCompany() {
      const queryString = getValue(this, '$route.query', {});

      const response = await this.createCompany({
        subscriptions: this.subscriptionGroups,
        queryString,
      });

      this.track(response);
      const modalParams = pick(getValue(response, 'admin'), [
        'idp_uuid',
        'expires_at',
        'refresh_token',
        'token',
      ]);
      this.modalSetup = {
        ...this.modalSetup,
        props: {
          ...genericSuccessArgs(this.$t),
          isOpen: true,
          title: this.$t('refactor.company_signup.titles.company_created'),
          primaryCallToActionText: this.$t('refactor.company_signup.actions.next'),
          primaryCallToAction: () => this.onClickModalPrimaryButton(modalParams),
        },
      };
    },
    async signupCompany() {
      try {
        this.trackInteractionEvent({
          eventName: GTM_EVENTS.clickAction,
          eventData: {
            click_text: 'submit-button',
            area: GTM_CLICK_ACTIONS_AREAS.cta,
          },
        });
        this.loading = true;

        if (this.linkAccount.isLinkAccount) {
          await this.submitLinkAccount();
        } else {
          await this.createNewCompany();
        }
      } catch (error) {
        logger.message(error, LOG_TYPE.error);

        const translatableKey = getValue(error, 'response.data.key') || getValue(error, 'data.key') || getValue(error, 'key');
        const phraseKeyError = `refactor.company_signup.errors.${translatableKey}`;
        const errorKey = this.$i18n.keyExists(phraseKeyError, 'strict')
          ? phraseKeyError
          : 'notifications.whooops';

        this.modalSetup = {
          ...this.modalSetup,
          props: {
            ...genericErrorArgs(this.$t),
            isOpen: true,
            title: this.$t('notifications.whooops'),
            description: this.$t(errorKey),
            primaryCallToActionText: this.$t('buttons.close'),
            primaryCallToAction: () => { this.modalSetup.props.isOpen = false; },
          },
        };
      } finally {
        this.modalSetup.isOpen = true;
        this.loading = false;
      }
    },
    handlerFormSubmit() {
      const query = getValue(this, '$route.query', {});

      this.doneStep(STORE_STEPS.two);
      this.updateStep(STORE_STEPS.three);
      this.$router.push({ name: COMPANY_SIGN_UP_ROUTES.step3, query }).catch(navigationErrorHandler);
    },
    track({ admin, company }) {
      // Track Company
      this.trackSegmentGroup({
        id: company.uuid,
        data: {
          ...pick(company, ['address', 'email', 'phone']),
          createdAt: moment().format(DATETIME_FORMATS.filter),
          employees: company.size,
          id: company.uuid,
          name: company.commercialName,
        },
      });

      const companyEventData = {
        ...pick(company, ['address', 'email']),
        company_name: company.commercialName,
      };

      this.trackInteractionEvent({
        eventName: GTM_EVENTS.b2BCompanyCreated,
        eventData: companyEventData,
      });

      this.trackInteractionEvent({
        eventName: GTM_EVENTS.signUp,
        eventData: {
          method: 'email',
          lead_type: GTM_SIGN_UP_EVENT_VALUES.adminB2B,
        },
      });

      this.trackSegment({
        name: SEGMENT_EVENTS.B2B_COMPANY_CREATED,
        data: companyEventData,
      });

      // Track Employee to Company
      this.trackSegment({
        name: SEGMENT_EVENTS.B2B_EMPLOYEE_CREATED,
        data: {
          ...pick(company, ['address', 'email', 'uuid']),
          company_name: company.commercialName,
          groupId: company.uuid,
          company: company.uuid,
          uuid: admin.uuid,
        },
      });
    },

    onClickModalPrimaryButton(data) {
      const {
        idp_uuid: idpUuid,
        expires_at: expiresAt,
        refresh_token: refreshToken,
        token,
      } = data;

      if (isPlainObject(data) && idpUuid && expiresAt && refreshToken && token) {
        this.$router.replace({
          name: 'ImpersonationView',
          query: {
            access_token: token,
            idpUuid,
            expiresAt,
            webapp_impersonate: true,
          },
        }).catch(navigationErrorHandler);
      } else {
        this.$router.push('/', () => window.location.reload());
      }
    },

    trackAndAcceptLegalDocuments(uuids = []) {
      this.trackSegment({
        name: SEGMENT_EVENTS.TERMS_ACCEPTED,
      });

      this.acceptLegalDocument(uuids);
    },
  },
};
</script>
