<template>
  <div
    v-if="data"
    class="ExtraInformationComponent"
    data-test-id="extra-information-component"
  >
    <ui-datetimepicker
      v-validate.blurinput="{
        isRequired: {
          message: $t('refactor.company_signup.errors.mandatory'),
          whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),
        },
        isValidBirthday: birthdateValidators,
      }"
      :date.prop="data.birthDate ? moment(data.birthDate) : null"
      :label="$t('refactor.user_signup.step_3.fields.birth_date')"
      :locale="locale"
      autoformat
      hideicon
      class="w-100 d-block mb-4"
      data-test-id="user-signup_form_input-birthdate"
      name="birthDate"
      skiptime
      external-validation
      @datechanged="({ detail }) => setBirthDate(detail)"
      @focusinput="resetInputValidation"
    />
    <ui-location-input
      v-validate.blurinput="{
        isRequired: {
          message: $t('refactor.user_signup.step_4.errors.location.required'),
          whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),
        },
      }"
      :label="$t('refactor.user_signup.step_4.fields.address')"
      :placeholder="$t(addressHintKey)"
      :value="displayAddress"
      consent
      data-test-id="user-signup_form_input-location"
      class="w-100 d-block mb-4"
      name="address"
      data-masked
      @changeplace="({ detail }) => address(detail)"
      @focusinput="resetInputValidation"
    />
    <ui-text-input
      :label="$t('refactor.user_signup.step_4.fields.additional_address')"
      :placeholder="$t(addressDetailsHintKey)"
      :value="data.addressDetails"
      class="w-100 d-block mb-4"
      name="additional_address"
      @changevalue="({ detail }) => addressDetails(detail)"
    />
    <ui-text-input
      v-if="isItalianUserComputed"
      ref="italianFiscalCode"
      v-validate.blurinput="{
        isRequired: {
          message: $t('refactor.user_signup.step_4.errors.italian_fiscal_code.required'),
          whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),
        },
        isPattern: {
          pattern: ITALY_COMPANY_VALIDATION_PATTERN.userCodiceFiscale,
          message: $t('refactor.user_signup.step_4.errors.italian_fiscal_code.format'),
        },
      }"
      :label="$t('refactor.user_signup.step_4.fields.fiscal_code_label')"
      :placeholder="$t('refactor.user_signup.step_4.fields.italian_fiscal_code_placeholder')"
      :value="italianFiscalCode"
      class="w-100 d-block"
      data-test-id="user-signup_form_input-italian-fiscal-code"
      name="italian_fiscal_code"
      @changevalue="({ detail }) => changeItalianFiscalCode(detail)"
      @focusinput="resetInputValidation"
    />
    <div
      v-if="isItalianUserComputed"
      class="emobg-font-small mb-4 pl-1 pt-1 emobg-color-ink-light"
    >
      {{ $t('refactor.user_signup.step_4.fields.italian_fiscal_code_subtitle') }}
    </div>
    <PhoneNumberWrapper
      :country="countryCode"
      :label="$t('refactor.user_signup.step_4.fields.phone_number')"
      :on-blur="trackAddPhone"
      :value="data.phone"
      data-test-id="user-signup_form_input-phone"
      blur-validation
      class="w-100 d-block"
      name="phone"
      @change="phone"
      @isValid="setIsPhoneNumberValid"
    />
    <div class="emobg-font-small mb-5 pl-1 pt-1 emobg-color-ink-light">
      {{ $t('refactor.user_signup.step_3.mobile') }}
    </div>
  </div>
</template>
<script>
import find from 'lodash/find';
import get from 'lodash/get';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import { Validate } from '@emobg/vue-base';
import { COUNTRIES_ISO_CODES, formatDate, ITALY_COMPANY_VALIDATION_PATTERN } from '@emobg/web-utils';
import PhoneNumberWrapper from '@/components/PhoneNumberWrapper/PhoneNumberWrapper';
import { useMaps } from '@/composable/App/Map/useMaps';
import SegmentMixin from '@/mixins/Segment';
import { SEGMENT_EVENTS } from '@/vue/constants';
import moment from 'moment';
import { SIGNUP_AGE } from '@/domains/Account/SignUp/Company/constants/general';
import { GENERIC_MODULE, USER_MODULE } from '../../constants/modules';

import {
  birthdateValidators,
  getAddressDetailsHintKey,
  getAddressHintKey,
  isItalianUserOrCompany,
} from '../../helpers';
import { DEFAULT_SIGNUP_RESTRICTIONS } from '../constants/restrictions.const';
import { resetInputValidation } from '../../../utils/validation.util';

export default {
  name: 'ExtraInformationComponent',
  components: {
    PhoneNumberWrapper,
  },
  directives: {
    Validate,
  },
  mixins: [
    SegmentMixin,
  ],
  setup() {
    const { googleMapsAutocompleteService, googleMapsGeocoderService } = useMaps();

    return { googleMapsAutocompleteService, googleMapsGeocoderService, moment };
  },
  computed: {
    // TODO [CF-547]: Refactor User Signup store
    ...mapGetters(USER_MODULE, [
      'italianFiscalCode',
      'countryCode',
      'addressCountryCode',
    ]),

    // TODO [CF-547 Refactor User Signup store
    ...mapState(USER_MODULE, {
      data: state => state.data,
      b2b: state => state.b2b,
    }),

    // TODO [CF-548]: Refactor Generic Signup store
    ...mapState(GENERIC_MODULE, {
      locale: state => state.locale,
      countries: state => state.countries,
    }),
    minDate() {
      return moment().subtract(SIGNUP_AGE.max, 'years');
    },
    maxDate() {
      const companyCountry = this.countryCode;
      const country = find(this.countries, { code: companyCountry });
      const minDriverAge = get(this.b2b, 'company.minDriverAge')
        || get(this.b2b, 'csOperator.minDriverAge')
        || get(this.data, 'country.minDriverAge')
        || get(country, 'minDriverAge', DEFAULT_SIGNUP_RESTRICTIONS.minAge);

      return moment().subtract(minDriverAge, 'years');
    },

    isItalianUserComputed() {
      return isItalianUserOrCompany(this.countryCode, this.addressCountryCode);
    },
    displayAddress() {
      return get(this, 'data.address.address');
    },
  },
  created() {
    this.addressHintKey = getAddressHintKey.call(this, this.countryCode);
    this.addressDetailsHintKey = getAddressDetailsHintKey.call(this, this.countryCode);
    this.ITALY_COMPANY_VALIDATION_PATTERN = ITALY_COMPANY_VALIDATION_PATTERN;
    this.birthdateValidators = birthdateValidators({
      $t: this.$t,
      minDate: this.minDate,
      maxDate: this.maxDate,
    });
    this.COUNTRIES_ISO_CODES = COUNTRIES_ISO_CODES;
  },
  methods: {
    resetInputValidation,
    // TODO [CF-547]: Refactor User Signup store
    ...mapMutations(USER_MODULE, [
      'address',
      'addressDetails',
      'birthDate',
      'phone',
      'setIsPhoneNumberValid',
    ]),
    // TODO [CF-547]: Refactor User Signup store
    ...mapActions(USER_MODULE, [
      'updateItalianFiscalCode',
    ]),
    trackAddPhone() {
      this.trackSegment({
        name: SEGMENT_EVENTS.SET_PHONE_NUMBER,
        data: {
          phone: this.data.phone,
        },
      });
    },
    changeItalianFiscalCode(italianFiscalCode) {
      const italianFiscalCodeField = get(this, '$refs.italianFiscalCode');
      italianFiscalCodeField.value = italianFiscalCode.toUpperCase();

      this.updateItalianFiscalCode(italianFiscalCode.toUpperCase());
    },
    setBirthDate(newBirthdate) {
      const convertedNewBirthdate = moment(newBirthdate);

      if (convertedNewBirthdate && convertedNewBirthdate.isValid()) {
        this.trackSegment({
          name: SEGMENT_EVENTS.BIRTHDATE_ADDED,
          data: formatDate(this.data.birthDate),
        });
      }

      this.birthDate(
        convertedNewBirthdate && convertedNewBirthdate.isValid()
          ? convertedNewBirthdate.toDate()
          : newBirthdate,
      );
    },
  },
};
</script>
