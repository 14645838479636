var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'mb-4 PromoCode',
    _vm.isPromoCodeVisible ? 'PromoCode--active' : '',
    _vm.forceErrorOnWrongPromoCode && _vm.isPromoCodeVisible ? 'PromoCode--error' : '',
  ]},[_c('Transition',{attrs:{"name":"fade-slide"}},[(!_vm.isPromoCodeVisible)?_c('a',{staticClass:"emobg-font-weight-bold pointer text-underline position-absolute",attrs:{"href":"javascript:void(0)","data-test-id":"user-signup_form_button-promocode"},on:{"click":_vm.triggerPromoCodeStatus}},[_vm._v(" "+_vm._s(_vm.$t('refactor.user_signup.step_4.fields.promo_code'))+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex"},[_c('Transition',{attrs:{"name":"delayed-fade-slide"}},[(_vm.isPromoCodeVisible)?_c('ui-text-input',{directives:[{name:"validate",rawName:"v-validate.blurinput",value:({
          isPromoCodeWrong: _vm.isPromoCodeWrong,
        }),expression:"{\n          isPromoCodeWrong,\n        }",modifiers:{"blurinput":true}}],ref:"promoCodeElement",staticClass:"w-100 d-block",attrs:{"label":_vm.$t('refactor.user_signup.step_4.fields.promo_code_label'),"icon-left":_vm.ICONS.removeFilled,"value":_vm.promoCodeValue,"name":"promoCode","reverse":"","data-test-id":"user-signup_form_input-promocode"},on:{"changevalue":({ detail }) => _vm.promoCode(detail),"clickicon":_vm.triggerPromoCodeStatus,"focusinput":_vm.resetInputValidation}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }