<template>
  <div class="AlreadyHaveAccount d-flex pr-4">
    <div class="pr-2 emobg-color-ink">
      {{ $t('refactor.user_signup.general.already_account') }}
    </div>
    <div>
      <span
        class="emobg-color-primary emobg-link emobg-font-weight-semibold"
        @click="buttonRedirection"
      >
        {{ $t('refactor.user_signup.general.login') }}
      </span>
    </div>
  </div>
</template>
<script>
import { useRouter } from 'vue-router/composables';
import { useTheme } from '@/composable/Theme/useTheme';
import { detectPlatform } from '@emobg/web-utils';

import { ANDROID, IOS } from '@/domains/Shared/components/UseMobile/constants/appLinks.const';

export default {
  name: 'AlreadyHaveAccount',
  setup() {
    const router = useRouter();
    const { fetchButtonSpecs } = useTheme();
    const { isAndroid, isIOS } = detectPlatform();

    return {
      router,
      fetchButtonSpecs,
      isAndroid,
      isIOS,
    };
  },
  methods: {
    buttonRedirection() {
      const links = {
        android: ANDROID,
        ios: IOS,
      };

      if (window.matchMedia('(max-width: 992px)').matches && (this.isAndroid || this.isIOS)) {
        window.location.href = this.isAndroid ? links.android : links.ios;
      }
      window.location.href = this.router.resolve('/').href;
    },
  },
};
</script>
