<template>
  <div
    data-test-id="company_signup_form-step_1"
    class="Step__form px-lg-0 px-3 pb-0 p-lg-0 mt-2 mt-md-5"
  >
    <div>
      <ui-validate @status="({ detail }) => formStatus = detail">
        <h1
          data-test-id="title-text"
          class="mb-5"
        >
          {{ $t('refactor.company_signup.step_1.title') }}
        </h1>

        <ui-text-input
          :ref="STEP_1_REFS.companyName"
          v-validate.blurinput="{
            isRequired: {
              message: $t('refactor.company_signup.errors.mandatory'),
              whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),
            },
          }"
          :name="STEP_1_REFS.companyName"
          :label="$t('refactor.company_signup.fields.company_name')"
          :value="data.companyName"
          data-test-id="name-input_text"
          class="d-block mb-3"
          @changevalue="({ detail }) => companyName(detail)"
          @focusinput="resetInputValidation"
        />

        <div class="mb-3">
          <ui-skeleton
            v-show="loadingCountries"
            height="66"
          />
          <CountrySelect
            v-if="!loadingCountries"
            :reference="STEP_1_REFS.country"
            :value="data.serviceCountryCode"
            :name="STEP_1_REFS.country"
            :label="$t('refactor.company_signup.fields.country', { operator: appName })"
            :placeholder="$t('refactor.company_signup.fields.country_placeholder')"
            :countries-options="countriesOptions"
            data-test-id="service_country-select"
            @on:country-change="setCountry"
          />
        </div>

        <div class="mb-3">
          <ui-skeleton
            v-show="loadingCountries"
            height="93"
          />
          <template v-if="!loadingCountries">
            <InputTax
              name="inputTax"
              :model-value="data.vatNumber"
              :invalid-tax-numbers="invalidTaxNumbers"
              :label="$t('refactor.company_signup.fields.tax')"
              :country="data.serviceCountryCode || COUNTRIES_ISO_CODES.spain"
              data-test-id="company_vat_number-input_text"
              class="d-block mt-4"
              @change="setTaxNumber"
              @isValid="isValid => isTaxNumberValid = isValid"
            />
            <div
              class="emobg-font-small pl-1 pt-1 emobg-color-ink-light"
              data-test-id="vat_number-caption_text"
            >
              {{ $t('refactor.company_signup.fields.tax_bottom') }}
            </div>
          </template>
        </div>

        <div
          v-show="isItalianCompany"
          class="mb-3"
        >
          <ui-text-input
            ref="italianFiscalCode"
            v-validate.blurinput="{
              isRequired: {
                message: $t('refactor.company_signup.errors.mandatory'),
                whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),
              },
              isPattern: {
                pattern: ITALY_COMPANY_VALIDATION_PATTERN.companyCodiceFiscale,
                message: $t('refactor.company_signup.errors.italian_fiscal_code.format')
              }
            }"
            data-test-id="italian_fiscal_code-input_text"
            name="italian_fiscal_code"
            class="d-block"
            :label="$t('refactor.company_signup.fields.fiscal_code_label')"
            :placeholder="$t('refactor.company_signup.fields.italian_fiscal_code_placeholder')"
            :value="data.italianFiscalCode"
            @changevalue="({ detail }) => setItalianFiscalCode(detail)"
            @focusinput="resetInputValidation"
          />
          <div
            data-test-id="italian_fiscal_code-caption_text"
            class="emobg-font-small pl-1 pt-1 emobg-color-ink-light"
          >
            {{ $t('refactor.company_signup.fields.italian_fiscal_code_subtitle') }}
          </div>
        </div>

        <div
          v-show="isFrenchCompany"
          class="mb-3"
        >
          <ui-text-input
            v-validate.blurinput="{
              isRequired: {
                message: $t('refactor.company_signup.errors.mandatory'),
                whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),
              },
              isPattern: {
                pattern: FRENCH_COMPANY_VALIDATION_PATTERN.siretNumber,
                message: $t('refactor.company_signup.errors.siret_number.format'),
              },
            }"
            data-test-id="siret_number-input"
            name="siretNumber"
            class="d-block"
            :label="$t('refactor.company_signup.fields.siret_number')"
            :placeholder="$t('refactor.company_signup.fields.siret_number_placeholder')"
            :value="data.siretNumber"
            @changevalue="({ detail }) => setSiretNumber(detail)"
            @focusinput="resetInputValidation"
          />
          <div class="emobg-font-small pl-1 pt-1 emobg-color-ink-light">
            {{ $t('refactor.company_signup.fields.siret_number_subtitle') }}
          </div>
        </div>

        <div class="mb-3">
          <ui-location-input
            v-validate.blurinput="{
              isRequired: {
                message: $t('refactor.company_signup.errors.mandatory'),
                whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),
              },
            }"
            :label="$t('business_profile.company.company_address_label')"
            :allowed-countries.prop="[data.serviceCountryCode || COUNTRIES_ISO_CODES.spain]"
            :value="address"
            :placeholder="$t(addressHintKey)"
            consent
            data-test-id="address-location_input"
            class="w-100"
            @changeplace="({ detail }) => companyAddress(detail)"
            @focusinput="resetInputValidation"
          />
          <div
            data-test-id="company_address-caption_text"
            class="emobg-font-small pl-1 pt-1 emobg-color-ink-light"
          >
            {{ $t('refactor.company_signup.fields.address_bottom', { operator: appName }) }}
          </div>
        </div>

        <ui-text-input
          name="extraCompanyAddress"
          :label="$t('refactor.user_signup.step_4.fields.additional_address')"
          :placeholder="$t(addressDetailsHintKey)"
          :value="data.extraCompanyAddress"
          data-test-id="extra_address-input_text"
          class="d-block mb-3"
          @changevalue="({ detail }) => extraCompanyAddress(detail)"
        />

        <ui-select
          :ref="STEP_1_REFS.legalForm"
          v-validate="{
            isRequired: {
              message: $t('refactor.company_signup.errors.mandatory'),
            },
          }"
          name="legalForm"
          :label="$t('refactor.company_signup.fields.legal_form')"
          :placeholder="$t('refactor.company_signup.fields.legal_form_placeholder')"
          :value="data.legalForm"
          :no-results-label="$t('refactor.company_signup.fields.vat_no_results')"
          :options.prop="legalFormOptions"
          data-test-id="legal_form_address-select"
          class="d-block mb-3"
          @selectoption="({ detail }) => legalForm(detail)"
        />

        <ui-select
          v-validate="{
            isRequired: {
              message: $t('refactor.company_signup.errors.mandatory'),
            },
          }"
          name="sectorEntity"
          :label="$t('refactor.company_signup.fields.sector_entity')"
          :placeholder="$t('refactor.company_signup.fields.sector_entity_placeholder')"
          :no-results-label="$t('refactor.company_signup.fields.vat_no_results')"
          :value="data.sectorEntity"
          :options.prop="sectorOfEntitiesOptions"
          data-test-id="sector_entity-select"
          class="d-block mb-3"
          @selectoption="({ detail }) => sectorEntity(detail)"
        />

        <ui-select
          v-validate="{
            isRequired: {
              message: $t('refactor.company_signup.errors.mandatory'),
            },
          }"
          name="companySize"
          :label="$t('refactor.company_signup.fields.company_size')"
          :placeholder="$t('refactor.company_signup.fields.company_size_placeholder')"
          :value="data.companySize"
          :options.prop="companySizesOptions"
          data-test-id="size-select"
          class="d-block mb-3"
          @selectoption="({ detail }) => companySize(detail)"
        />

        <div
          v-show="isItalianCompany"
          class="row flex-wrap mt-4"
        >
          <div class="col-sm-7 col-12">
            <p
              class="mb-1"
              v-html="$t('refactor.company_signup.extra_documents.certificate_title')"
            />
            <p class="emobg-font-x-small emobg-color-ink-light">
              {{ $t('refactor.company_signup.extra_documents.certificate_subtitle', { maxSize: italianCertificateMaxSize }) }}
            </p>
          </div>
          <div class="col-sm-5 col-12 d-flex justify-content-end">
            <ui-file-drop
              :max-size="ITALY_CERTIFICATE_MAX_SIZE"
              :file-types.prop="[UPLOAD_FILE_TYPES.pdf]"
              class="w-100 h-100"
              @filechanged="({ detail }) => setItalyCertificate(detail)"
            >
              <div class="emobg-color-primary emobg-font-small mt-2">
                {{ $t('complete_documentation.manual_upload.upload_driving_license.upload') }}
              </div>
            </ui-file-drop>
          </div>
        </div>

        <ui-button
          v-bind="fetchButtonSpecs()"
          :disabled="!isFormValid"
          :loading="isLoading"
          :size="SIZES.large"
          :type="BUTTON_TYPES.submit"
          data-test-id="submit-button"
          class="d-block mb-3 pt-3"
          @clickbutton="submit"
        >
          {{ $t('refactor.company_signup.actions.create') }}
        </ui-button>
      </ui-validate>
      <AlreadyHaveAccount class="mt-5" />
      <div class="d-flex flex-wrap my-3">
        <span
          class="emobg-color-ink pr-2"
          data-test-id="user_signup_redirect-text"
        >
          {{ $t('refactor.company_signup.step_1.redirect_to_step1_text') }}
        </span>
        <RouterLink
          :to="userSignUpStep1"
          class="emobg-font-weight-semibold emobg-color-primary"
          data-test-id="user_signup_redirect-button"
        >
          {{ $t('refactor.company_signup.step_1.redirect_to_step1_link') }}
        </RouterLink>
      </div>
    </div>
    <UseMobile />
  </div>
</template>

<script>
import every from 'lodash/every';
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import round from 'lodash/round';
import size from 'lodash/size';
import toLower from 'lodash/toLower';
import toUpper from 'lodash/toUpper';

import { mapActions, mapMutations, mapState } from 'vuex';
import Cookie from 'js-cookie';

import { BUTTON_TYPES, Validate } from '@emobg/vue-base';

import {
  COOKIE_NAMES,
  COUNTRIES_ISO_CODES,
  FRENCH_COMPANY_VALIDATION_PATTERN,
  ITALY_COMPANY_VALIDATION_PATTERN,
  navigationErrorHandler,
  rehydrate,
  UPLOAD_FILE_TYPES,
} from '@emobg/web-utils';

import config from '@/config';
import InputTax from '@/components/InputTax/InputTax';
import CountrySelect from '@/components/CountrySelect/CountrySelect.vue';
import { useMaps } from '@/composable/App/Map/useMaps';
import { SEGMENT_EVENTS } from '@/vue/constants';
import { STORE_STEPS } from '@/constants/signUpSteps';
import SegmentMixin from '@/mixins/Segment';
import { useTheme } from '@/composable/Theme/useTheme';

import AlreadyHaveAccount from '@/domains/Account/components/AlreadyHaveAccount';
import UseMobile from '@Shared/components/UseMobile/UseMobile';
import SIGN_UP_ROUTES from '../../router/routes-names';
import { COMPANY_MODULE, GENERIC_MODULE } from '../../constants/modules';
import { STEP_1_REFS } from '../constants/general';
import { ITALY_CERTIFICATE_MAX_SIZE } from '../constants/ItalyCertificate';
import { getAddressDetailsHintKey, getAddressHintKey, validateRefs } from '../../helpers';
import { resetInputValidation } from '../../../utils/validation.util';

export default {
  name: 'Step1',
  components: {
    AlreadyHaveAccount,
    CountrySelect,
    InputTax,
    UseMobile,
  },
  directives: {
    Validate,
  },
  mixins: [
    SegmentMixin,
  ],
  props: {
    auth: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { googleMapsAutocompleteService, googleMapsGeocoderService } = useMaps();
    const { fetchButtonSpecs } = useTheme();
    const SIGNUP_LOCAL_DATA = { name: 'company_signup', id: 'step1' };
    return { googleMapsAutocompleteService, googleMapsGeocoderService, fetchButtonSpecs, SIGNUP_LOCAL_DATA };
  },
  data() {
    return {
      isLoading: false,
      loadingCountries: false,
      isTaxNumberValid: true,
      isPersonalPhoneNumberValid: true,
      isBusinessPhoneNumberValid: true,
      invalidTaxNumbers: [],
      invalidEmails: [],
      formStatus: {},
    };
  },
  computed: {
    // TODO [CF-557]: Refactor Company Signup store
    ...mapState(COMPANY_MODULE, {
      data: state => state.data,
      countries: state => state.info.countries,
      countriesOptions: state => map(state.info.countries, ({ name, code }) => ({ label: name, value: code })),
      legalFormOptions: state => map(state.info.legalForms, legalForm => ({ label: legalForm, value: legalForm })),
      sectorOfEntitiesOptions: state => map(state.info.sectorEntities, ({ name, internalName }) => ({ label: name, value: internalName })),
      companySizesOptions: state => state.info.companySizes,
      address: state => get(state, 'data.companyAddress.address') || '',
    }),
    // TODO [CF-548]: Refactor Company Signup store
    ...mapState(GENERIC_MODULE, {
      titles: state => state.titles,
      locale: state => state.locale,
      titlesOptions: state => map(state.titles, ({ name, internalName }) => ({ label: name, value: internalName })),
    }),
    getLinkAccountUrl() {
      return this.$router.resolve({ name: '' }).href;
    },
    isItalianCompany() {
      return this.data.serviceCountryCode === COUNTRIES_ISO_CODES.italy;
    },
    isFrenchCompany() {
      return this.data.serviceCountryCode === COUNTRIES_ISO_CODES.france;
    },
    areDocumentsValid() {
      return !this.isItalianCompany || !!((this.data.extraDocuments.italianIdFront
        && this.data.extraDocuments.italianIdBack) || this.data.extraDocuments.italianPassport);
    },
    isItalianFiscalCodeValid() {
      return !!this.data.italianFiscalCode.match(ITALY_COMPANY_VALIDATION_PATTERN.companyCodiceFiscale);
    },
    isSiretNumberValid() {
      return FRENCH_COMPANY_VALIDATION_PATTERN.siretNumber.test(this.data.siretNumber);
    },
    isFormValid() {
      return every([
        this.data.companyName,
        this.data.serviceCountryCode,
        this.data.vatNumber && this.isTaxNumberValid,
        this.address,
        this.data.sectorEntity,
        this.data.legalForm,
        !this.isItalianCompany || this.isItalianFiscalCodeValid,
        !this.isItalianCompany || get(this.data, 'extraDocuments.italianCertificate'),
        !this.isFrenchCompany || this.isSiretNumberValid,
        this.formStatus.isValid,
      ], Boolean);
    },
  },
  async created() {
    this.appName = config.data.appName;
    this.BUTTON_TYPES = BUTTON_TYPES;
    this.COUNTRIES_ISO_CODES = COUNTRIES_ISO_CODES;
    this.undoStep(STORE_STEPS.one);
    this.STEP_1_REFS = STEP_1_REFS;
    this.UPLOAD_FILE_TYPES = UPLOAD_FILE_TYPES;
    this.userSignUpStep1 = { name: SIGN_UP_ROUTES.userStep1 };
    this.addressHintKey = getAddressHintKey.call(this, this.data.serviceCountryCode);
    this.addressDetailsHintKey = getAddressDetailsHintKey.call(this, this.data.serviceCountryCode);
    this.ITALY_COMPANY_VALIDATION_PATTERN = ITALY_COMPANY_VALIDATION_PATTERN;
    this.ITALY_CERTIFICATE_MAX_SIZE = ITALY_CERTIFICATE_MAX_SIZE;
    this.italianCertificateMaxSize = round(ITALY_CERTIFICATE_MAX_SIZE / 1048576);
    this.FRENCH_COMPANY_VALIDATION_PATTERN = FRENCH_COMPANY_VALIDATION_PATTERN;
    this.loadingCountries = true;
    await this.setCountries();
    this.loadingCountries = false;
    const countryQueryParam = get(this.$route, 'query.country');
    const currentLanguage = navigator.language;
    const splitLocale = currentLanguage.split('-');
    const countryCodeFromLocale = toUpper(
      size(splitLocale) === 2
        ? splitLocale[1]
        : currentLanguage,
    );
    const countryCode = countryQueryParam || countryCodeFromLocale;

    const storedData = rehydrate.get(this.SIGNUP_LOCAL_DATA.name);
    if (countryCode && !storedData) {
      const country = find(this.countries, countryObject => toLower(countryObject.code) === toLower(countryCode));
      if (country) {
        this.setCountry(country.code);
      }
    } else {
      this.setCompanyData(storedData.step1);
      rehydrate.clear(this.SIGNUP_LOCAL_DATA.name);
    }

    if (!this.data.serviceCountryCode) {
      this.setServiceCountryCode(COUNTRIES_ISO_CODES.spain);
      this.setCountry(COUNTRIES_ISO_CODES.spain);
    }

    this.trackSegment({
      name: SEGMENT_EVENTS.B2B_STEP_1,
      cache: true,
    });
  },
  mounted() {
    validateRefs(STEP_1_REFS, this.$refs, this.data);
  },
  methods: {
    get,
    includes,
    resetInputValidation,
    // TODO [CF-557]: Refactor Company Signup store
    ...mapMutations(COMPANY_MODULE, [
      'legalForm',
      'sectorEntity',
      'companySize',
      'extraCompanyAddress',
      'companyName',
      'companyAddress',
      'csOperator',
      'doneStep',
      'taxNumber',
      'title',
      'undoStep',
      'updateStep',
      'setVatNumber',
      'setServiceCountryCode',
      'setItalianFiscalCode',
      'setItalyCertificate',
      'legalForms',
      'sectorEntities',
      'setSiretNumber',
      'setCompanyData',
    ]),
    // TODO [CF-557]: Refactor Company Signup store
    ...mapActions(COMPANY_MODULE, [
      'setPreRegister',
      'setLegalDocuments',
      'setCountries',
    ]),
    goLinkAccount() {
      const route = this.$router.resolve({ name: 'LinkAdminAccount' });
      Cookie.set(COOKIE_NAMES.returnUrl, route.href);
      this.auth.login();
    },
    async submit() {
      this.isLoading = true;
      if (this.isFormValid) {
        const query = get(this, '$route.query', {});
        this.doneStep(STORE_STEPS.one);
        this.updateStep(STORE_STEPS.two);
        this.$router.push({ name: SIGN_UP_ROUTES.step2, query }).catch(navigationErrorHandler);
      }
      rehydrate.set(this.SIGNUP_LOCAL_DATA.name, this.SIGNUP_LOCAL_DATA.id, this.data);
      this.isLoading = false;
    },
    setCountry(countryCode) {
      const country = find(this.countries, { code: countryCode });
      if (country) {
        this.companyAddress({ address: '' });
        this.legalForm(null);
        this.sectorEntity(null);
        this.setServiceCountryCode(countryCode);
        this.csOperator(country.defaultCsOperatorUuid);
        this.legalForms([]);
        this.sectorEntities([]);
        this.setLegalDocuments(this.locale);
        if (this.data.vatNumber) {
          this.setVatNumber('');
        }
      }
    },
    setTaxNumber(taxNumber) {
      this.setVatNumber(taxNumber);
      if (this.isTaxNumberValid) {
        this.setPreRegister().catch(() => {
          this.invalidTaxNumbers.push(this.data.vatNumber);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  ui-skeleton {
    display: block;
  }
</style>
