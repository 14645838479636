<script>
import map from 'lodash/map';
import config from '@/config';

export default {
  name: 'SubscriptionGroupsComponent',

  props: {
    marketingNews: {
      type: Boolean,
      default: false,
    },
    subscriptions: {
      type: Array,
      default: () => [],
    },
  },

  created() {
    this.appName = config.data.appName;
  },

  methods: {
    onChangeSubscriptions({ uuid, isChecked }) {
      const subscriptions = map(this.subscriptions, subscription => ({
        ...subscription,
        checked: subscription.uuid === uuid ? isChecked : subscription.checked,
      }));

      this.$emit('update:subscriptions', subscriptions);
    },
  },
};
</script>

<template>
  <div>
    <label
      v-for="(subscription, index) in subscriptions"
      :key="subscription.uuid"
      class="d-flex mb-4"
    >
      <ui-checkbox
        :checked="subscription.checked"
        :size="SIZES.large"
        :data-test-id="`user-signup_form_input-checkbox-subscription-${index}`"
        class="d-inline-block mt-n1"
        @changevalue="({ detail }) => onChangeSubscriptions({ uuid: subscription.uuid, isChecked: detail })"
      />
      <span v-html="subscription.description" />
    </label>
  </div>
</template>
