var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Step__form px-lg-0 px-3 pb-0 p-lg-0 my-2 my-md-3 position-relative"},[_c('ui-form',{attrs:{"data-test-id":"user-signup_form_step-3"},on:{"submitform":_vm.handlerFormSubmit}},[_c('ui-validate',{on:{"status":({ detail }) => _vm.formStatus = detail}},[_c('h1',{staticClass:"mb-3",attrs:{"data-test-id":"signup-user-title"}},[_vm._v(" "+_vm._s(_vm.$t('refactor.user_signup.step_3.title'))+" ")]),_c('ui-alert',{staticClass:"d-block mb-5",attrs:{"color":_vm.webAppColors.informative,"icon":_vm.ICONS.infoFull,"data-test-id":"signup-user-subtitle"}},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]),_c('ui-select',{directives:[{name:"validate",rawName:"v-validate.select",value:({
          isRequired: {
            message: _vm.$t('refactor.company_signup.errors.mandatory'),
          },
        }),expression:"{\n          isRequired: {\n            message: $t('refactor.company_signup.errors.mandatory'),\n          },\n        }",modifiers:{"select":true}}],staticClass:"col-lg-6 d-block mb-3 pl-0 pr-lg-2 pr-0",attrs:{"data-test-id":"user-signup_form_select-title","name":"title","label":_vm.$t('refactor.user_signup.step_3.fields.title'),"value":_vm.data.title,"placeholder":_vm.$t('refactor.user_signup.step_3.fields.title_placeholder')},domProps:{"options":_vm.mappedTitles},on:{"selectoption":({ detail }) => _vm.setTitle(detail)}}),_c('div',{staticClass:"d-lg-flex"},[_c('ui-text-input',{directives:[{name:"validate",rawName:"v-validate.blurinput",value:({
            isRequired: {
              message: _vm.$t('refactor.company_signup.errors.mandatory'),
              whiteSpaceMessage: _vm.$t('refactor.company_signup.errors.mandatory'),
            },
          }),expression:"{\n            isRequired: {\n              message: $t('refactor.company_signup.errors.mandatory'),\n              whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),\n            },\n          }",modifiers:{"blurinput":true}}],staticClass:"col-lg-6 d-block mb-3 pl-0 pr-lg-2 pr-0",attrs:{"label":_vm.$t('refactor.user_signup.step_3.fields.first_name'),"value":_vm.data.firstName,"data-test-id":"user-signup_form_input-name","name":"firstName"},on:{"blurinput":_vm.onBlurTrackNameCreated,"changevalue":({ detail }) => _vm.setFirstName(detail),"focusinput":_vm.resetInputValidation}}),_c('ui-text-input',{directives:[{name:"validate",rawName:"v-validate.blurinput",value:({
            isRequired: {
              message: _vm.$t('refactor.company_signup.errors.mandatory'),
              whiteSpaceMessage: _vm.$t('refactor.company_signup.errors.mandatory'),
            },
          }),expression:"{\n            isRequired: {\n              message: $t('refactor.company_signup.errors.mandatory'),\n              whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),\n            },\n          }",modifiers:{"blurinput":true}}],staticClass:"col-lg-6 d-block mb-3 pl-0 pl-lg-2 pr-0",attrs:{"label":_vm.$t('refactor.user_signup.step_3.fields.last_name'),"value":_vm.data.lastName,"data-test-id":"user-signup_form_input-lastname","name":"lastName"},on:{"blurinput":_vm.onBlurTrackNameCreated,"changevalue":({ detail }) => _vm.setLastName(detail),"focusinput":_vm.resetInputValidation}})],1),(_vm.isTaxNumberVisible)?_c('InputTax',{class:[
          _vm.isIdNumberVisible ? 'mb-5' : 'mb-3',
          'w-100 d-block'
        ],attrs:{"country":_vm.countryCode,"invalid-tax-numbers":_vm.invalidTaxNumbers,"label":_vm.$t('refactor.company_signup.fields.tax'),"model-value":_vm.data.taxNumber,"placeholder":_vm.$t('refactor.company_signup.fields.tax'),"data-test-id":"company_vat_number-input_text","name":"taxNumber"},on:{"change":_vm.setTaxNumber,"isValid":isValid => _vm.isTaxNumberValid = isValid}}):_vm._e(),(_vm.isIdNumberVisible)?_c('ui-text-input',{directives:[{name:"validate",rawName:"v-validate.blurinput",value:({
          isRequired: {
            message: _vm.$t('refactor.company_signup.errors.mandatory'),
            whiteSpaceMessage: _vm.$t('refactor.company_signup.errors.mandatory'),
          },
        }),expression:"{\n          isRequired: {\n            message: $t('refactor.company_signup.errors.mandatory'),\n            whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),\n          },\n        }",modifiers:{"blurinput":true}}],class:[
          _vm.b2b.isCorporateUser ? 'mb-5' : 'mb-3',
          'w-100 d-block'
        ],attrs:{"label":_vm.$t('refactor.user_signup.step_3.fields.id_number'),"placeholder":_vm.$t('refactor.user_signup.step_3.fields.id_number_placeholder'),"value":_vm.data.idNumber,"name":"idNumber"},on:{"changevalue":({ detail }) => _vm.setIdNumber(detail),"focusinput":_vm.resetInputValidation}}):_vm._e(),(!_vm.b2b.isCorporateUser)?_c('ExtraInformationComponent'):_vm._e(),(!_vm.isEmployee)?_c('PromoCodeComponent'):_vm._e(),(_vm.info.legalDocuments)?_c('LegalDocumentsComponent',{attrs:{"accepted":_vm.acceptedLegalDocuments,"documents":_vm.info.legalDocuments},on:{"accept":({ value }) => _vm.trackAndAcceptLegalDocuments(value),"reject":({ value }) => _vm.rejectLegalDocument(value)}}):_vm._e(),(!_vm.ownsEmployeeData)?_c('SubscriptionGroupsComponent',{attrs:{"marketing-news":_vm.data.receiveNews,"subscriptions":_vm.subscriptionGroups,"use-subscriptions":_vm.areSubscriptionsEnabled},on:{"update:marketing-news":_vm.setReceiveNews,"update:subscriptions":_vm.setSubscriptionGroups}}):_vm._e(),_c('ui-button',_vm._b({staticClass:"d-block mt-5 mb-6",attrs:{"data-test-id":"user-signup_form_button-submit","size":_vm.SIZES.large,"disabled":_vm.isDisabled,"loading":_vm.loading || _vm.isPromoCodeCheckLoading,"type":_vm.BUTTON_TYPES.submit}},'ui-button',_vm.fetchButtonSpecs(),false),[_vm._v(" "+_vm._s(_vm.$t('buttons.send'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }