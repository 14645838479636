<script setup>
import { navigationErrorHandler } from '@emobg/web-utils';
import { webAppColors } from '@/constants/themes';
import { useRoute, useRouter } from 'vue-router/composables';

const router = useRouter();
const route = useRoute();

const props = defineProps({
  stepNumber: {
    type: Number,
    default: 1,
  },
  totalSteps: {
    type: Number,
    default: 3,
  },
  goToStep: {
    type: Object,
    default: () => ({}),
  },
});

const goToStep = (stepPath) => {
  router.push({ query: route.query, ...(stepPath || {}) }).catch(navigationErrorHandler);
};

const stepperColor = webAppColors.brand;
</script>

<template>
  <div class="navigator__wrapper">
    <div class="d-flex flex-nowrap justify-content-start align-items-center">
      <ui-icon
        v-if="props.stepNumber > 1"
        :icon="ICONS.arrowBack"
        :size="ICONS_SIZES.large"
        class="d-flex mr-3 cursor-pointer"
        @click="() => goToStep(props.goToStep)"
      />
      <div class="d-flex flex-row flex-wrap emobg-font-weight-bold emobg-color-primary w-100">
        {{ $t('signup.steps', { stepNumber: props.stepNumber, totalSteps: props.totalSteps }) }}
        <ui-progress-bar
          :current="props.stepNumber"
          :color="stepperColor"
          :total="totalSteps"
          gap="0"
          pill
          class="w-100"
        />
      </div>
    </div>
  </div>
</template>
