<template>
  <SignUpTemplate
    :right-title="$t('refactor.user_signup.left_side.title')"
    :right-subtitle="$t('refactor.user_signup.left_side.subtitle')"
    :right-image="userSignUpImage"
  >
    <div class="b2b-container">
      <ui-loader
        v-if="isLoading"
        class="col-12 pt-4"
      />
      <template v-if="displayLinkBlock">
        <h3
          class="mb-2 emobg-font-weight-bold emobg-color-ink"
          data-test-id="invitation-view-title"
        >
          {{ $t('refactor.b2b.email_exists.step1.title') }}
        </h3>
        <p class="mb-4 emobg-font-default">
          {{ $t('refactor.b2b.email_exists.step1.text') }}
        </p>
        <ui-button
          v-if="user"
          v-bind="fetchButtonSpecs()"
          data-test-id="invitation-view-link-button"
          :disabled="isButtonDisabled"
          @clickbutton="authenticate"
        >
          {{ $t('refactor.b2b.email_exists.step2.button') }}
        </ui-button>
      </template>
    </div>
  </SignUpTemplate>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import Cookie from 'js-cookie';
import { external } from '@emobg/web-api-client';
import {
  COOKIE_NAMES, getIdpUuid, getValue, isEmail, navigationErrorHandler, rehydrate,
} from '@emobg/web-utils';

import get from 'lodash/get';
import isNull from 'lodash/isNull';
import { decryptString } from '@/utils/crypto';
import externalApi from '@/vue/api/external/app.external-api';
import SESSION_STORAGE from '@/constants/session-storage';
import { useAuth } from '@/composable/Api/Auth/auth';
import { useTheme } from '@/composable/Theme/useTheme';

import { getThemedAssetByCategoryAndKey } from '@/utils/assetsRepository';
import { ASSETS_CATEGORY, PICTURES_KEYNAMES } from '@/constants/assetsRepository';
import { MFA } from '@/constants/mfa';
import { MFA_ROUTE_NAMES } from '@/domains/Account/MFAVerification/router/routes-names';
import SIGN_UP_ROUTES from '@/domains/Account/SignUp/router/routes-names';

import SignUpTemplate from '../../Templates/SignUpTemplate';
import { USER_MODULE } from '../../constants/modules';

export default {
  name: 'InvitationView',
  components: {
    SignUpTemplate,
  },
  props: {
    auth: {
      required: true,
      type: Object,
    },
  },

  setup() {
    const { idpUuid, isAuthenticated } = useAuth();
    const { fetchButtonSpecs } = useTheme();

    return { idpUuid, isAuthenticated, fetchButtonSpecs };
  },

  data() {
    return {
      isLoading: true,
      displayLinkBlock: false,
      isButtonDisabled: false,
      user: {},
    };
  },
  computed: {
    ...mapState(USER_MODULE, {
      b2b: state => state.b2b,
    }),
    userSignUpImage: () => getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.PICTURES, PICTURES_KEYNAMES.SIGNUP.b2b),
    isAuthenticatedUser() {
      return this.isAuthenticated;
    },
  },
  async created() {
    if (this.isAuthenticatedUser) {
      return;
    }

    if (this.isTokenInUrl()) {
      this.checkInvitation();
    }

    if (this.getPreregisteredData()) {
      this.checkPreregisterInvitation();
    }
  },
  async mounted() {
    if (this.isAuthenticatedUser) {
      Cookie.set(COOKIE_NAMES.returnUrl, '');
      let response = {};
      try {
        response = await externalApi.getUserDetail(this.idpUuid);
        this.user = response;
      } catch (error) {
        if (getValue(error, 'response.data.key', '') === MFA.mfaRequiredErrorKey) {
          const resolvedRoute = this.$router.resolve({ name: MFA_ROUTE_NAMES.mfaVerification });
          const isMfaVerificationRouteResolved = (getValue(resolvedRoute, 'resolved.name', '') === MFA_ROUTE_NAMES.mfaVerification);
          if (this.idpUuid && isMfaVerificationRouteResolved) {
            rehydrate.set(MFA.sendFirstCodeFlagStorageKey, getIdpUuid(this.idpUuid), true);
            const { invitationToken, invitationUUID } = this.$route.params;
            const redirectionParams = {
              redirectRoute: SIGN_UP_ROUTES.businessInvitationStep1,
              paramsCallback: { invitationToken, invitationUUID },
            };

            this.$router.push({ name: MFA_ROUTE_NAMES.mfaVerification, params: redirectionParams }).catch(navigationErrorHandler);

            return;
          }
        }
      }

      this.linkEmail(getValue(response, 'data.email'));
      this.linkAuthenticatedAccount();
    }
  },
  methods: {
    ...mapMutations(USER_MODULE, [
      'invitationUUID',
      'invitationToken',
      'country',
      'b2bCompany',
      'isEmployee',
      'linkEmail',
      'setEmail',
    ]),
    ...mapActions(USER_MODULE, [
      'checkInvitationB2B',
      'B2BLinkToExistingAccount',
    ]),
    authenticate() {
      Cookie.set(COOKIE_NAMES.returnUrl, window.location.href);
      this.auth.login();
    },
    isTokenInUrl() {
      return this.$route.params.invitationToken && this.$route.params.invitationUUID;
    },
    getPreregisteredData() {
      return get(this, '$route.params.employeeData');
    },
    getNextRoute() {
      const query = get(this, '$route.query', {});
      const { invitationToken, invitationUUID } = this.$route.params;

      return this.isTokenInUrl()
        ? { name: 'SignUp_User_Invitation-Step-1', params: { invitationToken, invitationUUID }, query }
        : {
          name: 'SignUp_User_Preregister_Invitation-Step-1',
          params: {
            employeeData: this.getPreregisteredData(),
          },
          query,
        };
    },
    async checkInvitation() {
      const { invitationToken, invitationUUID } = this.$route.params;
      this.invitationToken(invitationToken);
      this.invitationUUID(invitationUUID);
      await this.checkInvitationB2B();
      const { invitation } = this.b2b;
      const isExistingEmail = invitation && invitation.valid_invite && !invitation.invite.invitee_email;

      if (!isExistingEmail) {
        this.$router.push(this.getNextRoute());// .catch(navigationErrorHandler);
      }
      this.isLoading = false;
      this.displayLinkBlock = true;
    },
    async checkPreregisterInvitation() {
      // Data can be a `employeeUuid` or an `email`
      // `email` is encrypted in the middleware
      const employeeData = this.getPreregisteredData();
      const decryptedData = decodeURIComponent(decryptString(decodeURIComponent(employeeData)));
      const isValidEmail = !!decryptedData && isEmail(decryptedData).isValid;

      // TODO: [CSTMR-4234] Independent Stores for B2B & B2C Signup
      const response = await external.userCompanyAccount.getCheckCompanyCustomer({
        [isValidEmail ? 'email' : 'user_uuid']: isValidEmail ? decryptedData : employeeData,
      });

      this.isEmployee(true);
      this.setEmail(response?.email);
      const company = get(response, 'company') || {};
      const userCSOperatorUuid = get(company, 'dedicatedFleetCsOperator') || get(company, 'csOperator');
      this.country(get(company, 'countryCode'));
      this.b2bCompany({ ...company, csOperatorUuid: userCSOperatorUuid });
      this.$router.push(this.getNextRoute()).catch(navigationErrorHandler);

      this.displayLinkBlock = true;
      this.isLoading = false;
    },
    async linkAuthenticatedAccount() {
      await this.B2BLinkToExistingAccount();

      if (this.b2b.response) {
        window.sessionStorage.setItem(SESSION_STORAGE.feedbackModal, SESSION_STORAGE.linkAccount);
        window.location = '/';
      }
      if (isNull(this.b2b.response)) {
        this.isButtonDisabled = true;
      }
    },
  },
};
</script>
