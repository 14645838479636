<template>
  <div class="Step__form position-relative w-100 px-lg-0 px-3 pb-0 p-lg-0">
    <div class="my-2 my-md-3">
      <ui-validate @status="({ detail }) => formStatus = detail">
        <h1 data-test-id="signup-user-title">
          {{ $t('refactor.user_signup.step_2.title', { operator: appName }) }}
        </h1>
        <CountrySelect
          :countries-options="ubeeqoCountriesAdapter"
          :disabled="isEmployee"
          :label="$t('refactor.user_signup.step_2.fields.country')"
          :placeholder="$t('refactor.user_signup.step_2.fields.country_placeholder')"
          :value="currentCountry"
          class="d-block my-5"
          data-test-id="user-signup_form_select-country"
          name="country"
          reference="countryElement"
          @on:country-change="countryCode => changeCountry(countryCode)"
        />
        <ui-button
          v-bind="fetchButtonSpecs()"
          :disabled="isSubmitButtonDisabled"
          :loading="isLoadingLegalDocuments"
          :type="BUTTON_TYPES.submit"
          data-test-id="signup-user-next-button"
          @click="submit"
        >
          {{ $t('refactor.user_signup.step_2.button') }}
        </ui-button>
      </ui-validate>
    </div>
  </div>
</template>
<script>
import { BUTTON_TYPES, Validate } from '@emobg/vue-base';
import { COUNTRIES_ISO_CODES } from '@emobg/web-utils';
import find from 'lodash/find';
import get from 'lodash/get';
import { mapActions, mapMutations, mapState } from 'vuex';
import CountrySelect from '@/components/CountrySelect/CountrySelect.vue';
import config from '@/config';
import SegmentMixin from '@/mixins/Segment';
import { SEGMENT_EVENTS } from '@/vue/constants';
import { useTheme } from '@/composable/Theme/useTheme';

import { GENERIC_MODULE, USER_MODULE } from '../../constants/modules';
import { STEPS } from '../constants/steps.const';

export default {
  name: 'Step2View',

  components: {
    CountrySelect,
  },

  directives: {
    Validate,
  },

  mixins: [
    SegmentMixin,
  ],
  setup() {
    const { fetchButtonSpecs } = useTheme();

    return { fetchButtonSpecs };
  },
  data() {
    return {
      formStatus: undefined,
      isLoadingLegalDocuments: false,
    };
  },
  computed: {
    ...mapState(USER_MODULE, {
      currentCountry: state => get(state, 'data.country.code') || COUNTRIES_ISO_CODES.spain,
      done: state => state.done,
      b2b: state => state.b2b,
      isCorporateUser: state => state.b2b.isCorporateUser,
      isEmployee: state => state.b2b.isEmployee,
    }),
    ...mapState(GENERIC_MODULE, {
      ubeeqoCountries: state => state.countries,
      locale: state => state.locale,
    }),
    isSubmitButtonDisabled() {
      return !get(this, 'formStatus.isValid');
    },
    ubeeqoCountriesAdapter() {
      return this.ubeeqoCountries.map(country => ({
        label: country.name,
        value: country.code,
      }));
    },
  },
  watch: {
    formStatus: {
      handler(newStatus) {
        const isFormValid = get(newStatus, 'isValid');

        if (isFormValid) {
          this.doneStep(STEPS.two);
        } else {
          this.undoStep(STEPS.two);
        }
      },
      deep: true,
    },
  },
  created() {
    this.BUTTON_TYPES = BUTTON_TYPES;
    this.appName = config.data.appName;
  },
  mounted() {
    if (!this.done.step1) {
      this.$router.push({ path: `./${STEPS.one}`, query: this.$route.query });
    } else {
      this.changeCountry(this.currentCountry);
    }
  },
  methods: {
    ...mapMutations(USER_MODULE, [
      'country',
      'doneStep',
      'undoStep',
      'updateStep',
    ]),
    ...mapActions(USER_MODULE, [
      'setLegalDocuments',
    ]),
    handleFormSubmit() {
      const isFormValid = get(this, 'formStatus.isValid');

      if (isFormValid) {
        this.doneStep(STEPS.two);
        this.updateStep(STEPS.three);
        this.$router.push({ path: `./${STEPS.three}`, query: this.$route.query });
      } else {
        this.undoStep(STEPS.two);
        this.updateStep(STEPS.two);
      }
    },
    submit() {
      const isFormValid = get(this, 'formStatus.isValid');

      if (isFormValid) {
        this.handleFormSubmit();
      }
    },
    async changeCountry(countryCode) {
      this.isLoadingLegalDocuments = true;
      const country = find(this.ubeeqoCountries, { code: countryCode });
      this.country(country);
      await this.setLegalDocuments(this.locale);
      this.isLoadingLegalDocuments = false;
      this.trackSegment({
        name: SEGMENT_EVENTS.COUNTRY_SELECTED,
        data: {
          country: countryCode,
        },
      });
    },
  },
};
</script>
